import React from 'react';


class Notification extends React.Component{



    render(){


        return(<div className="notifications" data-v-notifications>

        <div className="alert alert-danger d-flex alert-dismissable" role="alert" data-v-notification-error>
  
          <div className="icon">
            <i className="la la-exclamation-triangle"></i>
          </div>
  
          <div className="flex-grow-1 align-self-center" data-v-notification-text>
            Vvveb 0.2 is now available! <a href="update">Update now</a>
          </div>
  
  
          <button type="button" className="btn-close mt-3" data-bs-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">
              
            </span>
          </button>
        </div>
  
        <div className="alert alert-success d-flex  alert-dismissable d-flex" role="alert" data-v-notification-success>
  
          <div className="icon">
            <i className="la la-check-circle"></i>
          </div>
  
          <div className="flex-grow-1 align-self-center" data-v-notification-text>
            You successfully read this important alert message.
          </div>
  
          <button type="button" className="btn-close mt-3" data-bs-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">
             
            </span>
          </button>
        </div>
  
        <div className="alert alert-primary d-flex alert-dismissable d-flex" role="alert" data-v-notification-info>
  
          <div className="icon">
            <i className="la la-info-circle"></i>
          </div>
  
          <div className="flex-grow-1 align-self-center" data-v-notification-text>
            You successfully read this important alert message.
          </div>
  
          <button type="button" className="btn-close mt-3" data-bs-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">
              
            </span>
          </button>
        </div>
  
      </div>);
    }
}

export default Notification;