import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import TopHeader from '../../components/topheader';
import  placeholder from "../../placeholder.svg";
import { GetAgentById,UpdatePassword } from '../../services/api.agent';
import { Navigate } from 'react-router';

class ChangePasswordAgent extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
        console.log(props)
        this.state={error:"",username:"",password:"",redirect:"",id:this.props.id}
        this.setEmail = this.setEmail.bind(this);
        this.setPhone = this.setPhone.bind(this);
        this.setFirstname = this.setFirstname.bind(this);
        this.setSurname = this.setSurname.bind(this);
        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.updatePassword = this.updatePassword.bind(this)
    }

    componentDidMount(){
      GetAgentById(this.state.id).then(res=>{
        console.log(res)
        if(res.username){
          this.setState({username:res.username})
        }
      })

    }


    updatePassword(){
      if(this.state.username==""){
        this.setState({error:"Username can not be empty"})
        return;
      }
      if(this.state.password==""){
        this.setState({error:"Password can not be empty"})
        return;
      }

      UpdatePassword(this.state.id,this.state.password).then(res=>{
        console.log(res)
        if(res.status){
          console.log("Success")
          this.setState({redirect:"/agent"})
        }
      })
    }

    setFirstname(evt){
      this.setState({firstname:evt.target.value})
    }

    setSurname(evt){
      this.setState({surname:evt.target.value})
    }

    setEmail(evt){
      this.setState({email:evt.target.value})
    }

    setPhone(evt){
      this.setState({phone:evt.target.value})
    }

    setUsername(evt){
      this.setState({username:evt.target.value})
    }

    setPassword(evt){
      this.setState({password:evt.target.value})
    }

    render(){
      let errorview=<div></div>
      if(this.state.error){

      }
      if(this.state.redirect=="/agent"){
        return <Navigate to="/agent" />
      }

        return(<div>



            <div id="container">
            
             
                
            <div className="sidebar">
            
            <div className="logo">
            
            
            <div className="logo-content">
                   <img src={logo}   />
                    
                   
                </div>
            
            </div>
            
            
            <Sidebar />
            
            </div>
                <div className="main">
            
                 
                 <TopHeader />
            
            
                  <div id="content">
            
            
                    <div className="actions">
            
                      <div className="title">
                        <h4 className="text-muted my-2">
                          <i className="icon-person-outline"></i>
                          <span>Change Agent Password</span>
                        </h4>
                      </div>
            
                       
            
                    </div>
            
            
                 
                    <div id="main-content">
           
              
  
 

        <div id="main-content" className="px-4">

          <div className="mb-3 row">
            <label  className="col-sm-2 col-form-label">Username</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" placeholder="Username" value={this.state.username} onChange={this.setUsername} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-2 col-form-label">Password</label>
            <div className="col-sm-10">
              <input type="password" className="form-control"  value={this.state.password} onChange={this.setPassword} />
            </div>
          </div>

          

         

          
          
          <div className="mb-3 mx-5  row"> 
                    <button onClick={this.updatePassword} className="btn btn-primary btn-icon ms-1">
                                    <i className="icon-add-circle-outline"></i>
                                    <span>Update Password</span>
                                    </button>
               </div>
        </div>
                
                
                </div>
  
                      
                    </div>
            
            
                  </div>
            
            
                </div>
             
            
            
                    </div>);
    }
}

export default ChangePasswordAgent;