import {useState} from "react";

export const useLocalStorage = (keyName,defaultValue)=>{
    const [storedValue,setStoredValue] = useState(()=>{

        try{
            console.log("Localstorage 0")
            const value = window.localStorage.getItem(keyName);
            if(value){
                console.log("Localstorage 1")
                return JSON.parse(value);

            }else{
                console.log("Localstorage 2")
                window.localStorage.setItem(keyName,JSON.stringify(defaultValue));
                return defaultValue;
            }

        }catch(err){
            console.log("Localstorage 3")
            return defaultValue;
        }

    });

    const setValue = (newValue)=>{
        try{
            console.log("Localstorage 4")
            window.localStorage.setItem(keyName,JSON.stringify(newValue))
        }catch(err){
            console.log("Localstorage 5")
            setStoredValue(newValue);
        }
    }
}