import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import {GetAllAdmin} from "../../services/api.admin";
import TopHeader from '../../components/topheader';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
//import DT from 'datatables.net-bs5';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import { Card, CardBody, CardHeader, CardTitle } from 'react-bootstrap';

const columns = [
  { data: 'id' },
  { data: 'username' },
  { data: 'token' },
  { data: 'status' },
  { data: 'id' },

];
class Devices extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
        this.getAdmin();
    }


      getAdmin(){
        GetAllAdmin().then(res=>{
          console.log(res)
        })
    }




    render(){

        return (<div>



<div id="container">

 
    
<div className="sidebar">

<div className="logo">


<div className="logo-content">
       <img src={logo}   />
        
       
    </div>

</div>


<Sidebar />

</div>
    <div className="main">

     
     <TopHeader />


      <div id="content">


        <div className="actions">

          <div className="title">
            <h4 className="text-muted my-2">
              <i className="icon-person-outline"></i>
              <span>Devices</span>
            </h4>
          </div>

          <div className="btns">
            <button type="button" data-bs-toggle="collapse" href="#filters" aria-expanded="false" aria-controls="filters" title="Filter" className="btn btn-outline-secondary me-2">
              <i className="icon-funnel-outline"></i>&nbsp;
              <i className="icon-chevron-down-outline small"></i>
            </button>

            <a href data-v-url-params='{"module":"user/user"}' className="btn btn-primary btn-icon ms-1">
              <i className="icon-add-circle-outline"></i>
              <span>Add Device</span>
            </a>
          </div>

        </div>


        <div id="filters" className="collapse mx-3">
          

           
            <div className="card card-block p-3">

              <div className="row">
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Search</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" name="filter[search]" value="" placeholder="User name" id="search" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Email</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" name="filter[email]" value="" placeholder="Email" id="Email" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Phone number</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" name="filter[phone_number]" value="" placeholder="Phone number" id="phone_number" />
                    </div>
                  </div>
                </div>


                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="input-model">Status</label>
                    <div className="col-sm-10">
                      <select name="filter[status]" id="filter-by-status" className="form-control">
                        <option value="">- All -</option>
                        <option value="active" data-v-option>Active</option>
                        <option value="inactive" data-v-option>Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-sm-2">
                  <button type="submit" id="button-filter" className="btn btn-primary btn-icon">
                    <i className="icon-funnel-outline"></i> Filter
                  </button>
                </div>
              </div>

            </div>
          
        </div>

        <div id="main-content">
{/** 
          <div className="notifications" data-v-notifications>

            <div className="alert alert-danger d-flex alert-dismissable" role="alert" data-v-notification-error>

              <div className="icon">
                <i className="la la-exclamation-triangle"></i>
              </div>

              <div className="flex-grow-1 align-self-center" data-v-notification-text>
                Vvveb 0.2 is now available! <a href="update">Update now</a>
              </div>


              <button type="button" className="btn-close mt-3" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  
                </span>
              </button>
            </div>

            <div className="alert alert-success d-flex  alert-dismissable d-flex" role="alert" data-v-notification-success>

              <div className="icon">
                <i className="la la-check-circle"></i>
              </div>

              <div className="flex-grow-1 align-self-center" data-v-notification-text>
                You successfully read this important alert message.
              </div>

              <button type="button" className="btn-close mt-3" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  
                </span>
              </button>
            </div>

            <div className="alert alert-primary d-flex alert-dismissable d-flex" role="alert" data-v-notification-info>

              <div className="icon">
                <i className="la la-info-circle"></i>
              </div>

              <div className="flex-grow-1 align-self-center" data-v-notification-text>
                You successfully read this important alert message.
              </div>

              <button type="button" className="btn-close mt-3" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  
                </span>
              </button>
            </div>

          </div>
*/}
          <div className="users card-block" data-v-users>
            

              <div className="bulk-actions px-2 mb-2" style={{display:"none"}}>
                
                <button type="submit" name="action" value="delete" aria-expanded="false" title="Delete selected" className="btn btn-outline-secondary bg-body-tertiary text-danger">
                  <i className="icon-trash-outline"></i> Delete selected
                </button>
              </div>


              <table className="table table-hover middle-align">
                <thead>
                  <tr>
                    <th className="col-cb">
                      <div className="checkbox_outer">

                        <label className="form-check">
                          <input type="checkbox" className="form-check-input" data-v-vvveb-action="table.bulkSelect" />
                          <span className="form-check-label"></span>
                          <span className="custom-control-description">&nbsp;</span>
                        </label>


                      </div>
                    </th>
                   
                    <th style={{maxWidth:"200px",textAlign:"center"}}>Id</th>
                    <th style={{maxWidth:"200px"}}>Device Identifier</th>
                    <th style={{maxWidth:"200px"}}>Device Name</th>
                    <th style={{maxWidth:"200px"}}>OS Version</th>
                    <th style={{width:"100px"}}>Device Version</th>
                    <th style={{width:"200px",textAlign:"center"}}>Actions</th>
                  </tr>
                </thead>
                <tbody>
           
                  <tr data-v-if-not="this.count >= 1">
                  <td colspan="100">
                    <div className="text-center p-4 bg-body-tertiary border">
                      <i className="icon-person-outline fs-1 mb-2"></i>
                      <div className="mt-3">No Device data to display!</div>
 

                    </div>
                  </td>
                </tr>
                </tbody>
                

              </table>
          </div>



          <div className="bottom clearfix">
            <div className="float-start"></div>
            <div className="float-end">

              <div id="pagination" data-pagination>
                <div data-v-if="pagecount > 1">

                  <form method="get" action="?" className="d-inline-block" data-current-url>
                    <span className="pages" data-count>100</span> items, page
                    <input type="text" name="page" className="form-control d-inline-block w-auto pages" size="1" data-current-page />
                    of <span className="pages" data-pagecount></span>
                  </form>

                  <nav className="d-inline-block">
                    <ul className="pagination">

                      <li className="page-item pager-first" data-first>
                        <a className="page-link" title="First page" href="#" data-page-url>
                          <i aria-hidden="true" className="icon-arrow-back-outline"></i>
                          <span className="sr-only">First</span>
                        </a>
                      </li>

                      <li className="page-item me-2 prev" data-prev>
                        <a className="page-link" href="#" aria-label="Previous" title="Previous page" data-page-url>
                          <i aria-hidden="true" className="icon-chevron-back-outline"></i>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>



                      <li className="page-item" data-page>
                        <a className="page-link" href="#" data-page-no data-page-url>1</a>
                      </li>
                      <li className="page-item" data-page>
                        <a className="page-link" href="#" data-page-no data-page-url>2</a>
                      </li>
                      <li className="page-item" data-page>
                        <a className="page-link" href="#" data-page-no data-page-url>3</a>
                      </li>
                      <li className="page-item" data-page>
                        <a className="page-link" href="#" data-page-no data-page-url>4</a>
                      </li>
                      <li className="page-item" data-page>
                        <a className="page-link" href="#" data-page-no data-page-url>5</a>
                      </li>

                      <li className="page-item ms-2 pager-next" data-next>
                        <a className="page-link" href="#" aria-label="Next" title="Next page" data-page-url>
                          <i aria-hidden="true" className="icon-chevron-forward-outline"></i>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>

                      <li className="page-item pager-last" data-last>
                        <a className="page-link" title="Last page" href="#" data-page-url>
                          <i aria-hidden="true" className="icon-arrow-forward-outline"></i>
                          <span className="sr-only">Last</span>
                        </a>
                      </li>

                    </ul>
                  </nav>
                </div>
              </div>



            </div>

          </div>

          
        </div>


      </div>


    </div>
  </div>


        </div>);

    }
}


export default Devices;