import React from 'react';
import ReactDOM from 'react-dom/client';
import './admin.css';
import App from './App';
import {RouterProvider } from "react-router-dom";
import router from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import store from "./store/store";
import {Provider} from "react-redux";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <Provider store={store}>
        <App />
      </Provider>
    </RouterProvider>
  </React.StrictMode>
);

