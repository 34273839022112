import React from "react";
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import TopHeader from '../../components/topheader';
import {GetNetwork} from "../../services/api.networks";
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
//import DT from 'datatables.net-bs5';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import { Card, CardBody, CardHeader, CardTitle } from 'react-bootstrap';

const columns = [
  { data: 'id' },
  { data: 'username' },
  { data: 'token' },
  { data: 'status' },
  { data: 'id' },

];


class Networks extends React.Component{

    constructor(props){
        super(props);
        this.props = props; 
        this.state={"redirect":"",data:(<div></div>),"module":"network"}
    }


    componentDidMount(){
      this.getNetwork();
    }


    noData(){
      return (<tr data-v-if-not="this.count >= 1">
        <td colspan="100">
          <div className="text-center p-4 bg-body-tertiary border">
            <i className="icon-person-outline fs-1 mb-2"></i>
            <div className="mt-3">No {this.state.module} to display!</div>
  
            <a href data-v-url-params='{"module":"user/user"}' className="btn btn-primary btn-icon mt-3">
              <i className="icon-add-circle-outline"></i> Add {this.state.module}
            </a>
  
          </div>
        </td>
      </tr>)
    }
  
  
    renderList(networks){
    return networks.map((network,i)=>{
      return (<tr>
        <td className="checkbox">
          <label className="form-check">
            <input type="checkbox" className="form-check-input" value="4" name="user_id[]" data-v-vvveb-action="table.selectRow" data-v-user_id />
            <span className="form-check-label"></span>
            <span className="custom-control-description">&nbsp;</span>
          </label>
        </td>
  
        <td>
           {network.id}
        
        </td>
  
        <td className="user">
          
            <span data-v-username>{network.name}</span>
         
  
        </td>
        <td>
          <span className="small"><img src={network.logo} width="50px" height="50px" /></span>
        </td>
       
  
        <td className="actions">
  
          <div className="btn-group">
            <a className="btn btn-outline-secondary" data-v-edit-url>
              <i className="la la-pencil-alt text-primary"></i>
              <span>Edit</span>
            </a>
            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
  
              <span className="visually-hidden">Toggle primary</span>
            </button>
            <ul className="dropdown-menu">
  
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item text-danger" data-v-delete-url onclick="return confirm('Are you sure?')">
                  <i className="icon-trash-outline"></i>
                  <span>Delete</span>
                </a>
              </li>
            </ul>
          </div>
  
        </td>
  </tr>);
    })
  
    }

    getNetwork(){
      GetNetwork().then(res=>{
          console.log(res)
          if(res.length>0){
            this.setState({"data":this.renderList(res)})
  
  
          }else{
            this.setState({"data":this.noData()})
          }
        })
    }



    render(){


        return(<div>



            <div id="container">
            
             
                
            <div className="sidebar">
            
            <div className="logo">
            
            
            <div className="logo-content">
                   <img src={logo}   />
                    
                   
                </div>
            
            </div>
            
            
            <Sidebar />
            
            </div>
                <div className="main">
            
                <TopHeader />
            
            
                  <div id="content">
            
            
                    <div className="actions">
            
                      <div className="title">
                        <h4 className="text-muted my-2">
                          <i className="icon-person-outline"></i>
                          <span>Networks</span>
                        </h4>
                      </div>
            
                      <div className="btns">
                        
            
                        <a href data-v-url-params='{"module":"user/user"}' className="btn btn-primary btn-icon ms-1">
                          <i className="icon-add-circle-outline"></i>
                          <span>Add network</span>
                        </a>
                      </div>
            
                    </div>
            
            
                   
            
                    <div id="main-content">
           
                      <div className="users card-block" data-v-users>
                        
            
                          <div className="bulk-actions px-2 mb-2" style={{display:"none"}}>
                            
                            <button type="submit" name="action" value="delete" aria-expanded="false" title="Delete selected" className="btn btn-outline-secondary bg-body-tertiary text-danger">
                              <i className="icon-trash-outline"></i> Delete selected
                            </button>
                          </div>
            
            
                          <table className="table table-hover middle-align">
                            <thead>
                              <tr>
                                <th className="col-cb">
                                  <div className="checkbox_outer">
            
                                    <label className="form-check">
                                      <input type="checkbox" className="form-check-input" data-v-vvveb-action="table.bulkSelect" />
                                      <span className="form-check-label"></span>
                                      <span className="custom-control-description">&nbsp;</span>
                                    </label>
            
            
                                  </div>
                                </th>
                               
                                <th style={{maxWidth:"200px",textAlign:"center"}}>Id</th>
                                <th style={{maxWidth:"200px"}}>Name</th>
                                <th style={{maxWidth:"200px"}}>Logo</th>
                                <th style={{width:"200px",textAlign:"center"}}>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data}
                           
                            </tbody>
                           
                          </table>
                      </div>
            
            
            
                      <div className="bottom clearfix">
                        <div className="float-start"></div>
                        <div className="float-end">
            
                          <div id="pagination" data-pagination>
                            <div data-v-if="pagecount > 1">
            
                              <form method="get" action="?" className="d-inline-block" data-current-url>
                                <span className="pages" data-count>100</span> items, page
                                <input type="text" name="page" className="form-control d-inline-block w-auto pages" size="1" data-current-page />
                                of <span className="pages" data-pagecount></span>
                              </form>
            
                              <nav className="d-inline-block">
                                <ul className="pagination">
            
                                  <li className="page-item pager-first" data-first>
                                    <a className="page-link" title="First page" href="#" data-page-url>
                                      <i aria-hidden="true" className="icon-arrow-back-outline"></i>
                                      <span className="sr-only">First</span>
                                    </a>
                                  </li>
            
                                  <li className="page-item me-2 prev" data-prev>
                                    <a className="page-link" href="#" aria-label="Previous" title="Previous page" data-page-url>
                                      <i aria-hidden="true" className="icon-chevron-back-outline"></i>
                                      <span className="sr-only">Previous</span>
                                    </a>
                                  </li>
            
            
            
                                  <li className="page-item" data-page>
                                    <a className="page-link" href="#" data-page-no data-page-url>1</a>
                                  </li>
                                  <li className="page-item" data-page>
                                    <a className="page-link" href="#" data-page-no data-page-url>2</a>
                                  </li>
                                  <li className="page-item" data-page>
                                    <a className="page-link" href="#" data-page-no data-page-url>3</a>
                                  </li>
                                  <li className="page-item" data-page>
                                    <a className="page-link" href="#" data-page-no data-page-url>4</a>
                                  </li>
                                  <li className="page-item" data-page>
                                    <a className="page-link" href="#" data-page-no data-page-url>5</a>
                                  </li>
            
                                  <li className="page-item ms-2 pager-next" data-next>
                                    <a className="page-link" href="#" aria-label="Next" title="Next page" data-page-url>
                                      <i aria-hidden="true" className="icon-chevron-forward-outline"></i>
                                      <span className="sr-only">Next</span>
                                    </a>
                                  </li>
            
                                  <li className="page-item pager-last" data-last>
                                    <a className="page-link" title="Last page" href="#" data-page-url>
                                      <i aria-hidden="true" className="icon-arrow-forward-outline"></i>
                                      <span className="sr-only">Last</span>
                                    </a>
                                  </li>
            
                                </ul>
                              </nav>
                            </div>
                          </div>
            
            
            
                        </div>
            
                      </div>
            
                      
                    </div>
            
            
                  </div>
            
            
                </div>
              </div>
            
            
                    </div>);
    }
}


export default Networks;