import React from 'react';



class Home extends React.Component{



    constructor(props){
        super(props);
        this.props = props;

    }

    render(){
        return(<div>
            <h1>Home</h1>
        </div>)
    }
}

export default Home;