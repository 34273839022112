import {useState} from "react";
//Config.rooturl="https://localhost:7175";
Config.rooturl="https://agentportal.cspconline.com:7775";
Config.token="";
Config.title="CSPC Rica Agent Portal";
function Config(props){
//const [rooturl,setRooturl]=useState("https://localhost:7175");
const [rooturl,setRooturl]=useState("https://agentportal.cspconline.com:7775");
const [title,setTitle]=useState("CSPC Rica Agent Portal");
const [token,setToken]=useState("");
}

export default Config;