import React from "react";
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import TopHeader from '../../components/topheader';
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "./index.css";

class Locations extends React.Component{


    constructor(props){
        super(props);
        this.props = props; 
    }

    render(){


        return(<div>



            <div id="container">
            
             
                
            <div className="sidebar">
            
            <div className="logo">
            
            
            <div className="logo-content">
                   <img src={logo}   />
                    
                   
                </div>
            
            </div>
            
            
            <Sidebar />
            
            </div>
                <div className="main">
            
                <TopHeader />
            
            
                  <div id="content">
            
            
                    <div className="actions">
            
                      <div className="title">
                        <h4 className="text-muted my-2">
                          <i className="icon-person-outline"></i>
                          <span>Locations</span>
                        </h4>
                      </div>
            
                      <div className="btns">
                        <button type="button" data-bs-toggle="collapse" href="#filters" aria-expanded="false" aria-controls="filters" title="Filter" className="btn btn-outline-secondary me-2">
                          <i className="icon-funnel-outline"></i>&nbsp;
                          <i className="icon-chevron-down-outline small"></i>
                        </button>
    
                      </div>
            
                    </div>
            
            
                    <div id="filters" className="collapse mx-3">
                      
            
                       
                        <div className="card card-block p-3">
            
                          <div className="row">
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="search">Search</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[search]" value="" placeholder="User name" id="search" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="search">Email</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[email]" value="" placeholder="Email" id="Email" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="search">Phone number</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[phone_number]" value="" placeholder="Phone number" id="phone_number" />
                                </div>
                              </div>
                            </div>
            
            
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="input-model">Status</label>
                                <div className="col-sm-10">
                                  <select name="filter[status]" id="filter-by-status" className="form-control">
                                    <option value="">- All -</option>
                                    <option value="active" data-v-option>Active</option>
                                    <option value="inactive" data-v-option>Inactive</option>
                                  </select>
                                </div>
                              </div>
                            </div>
            
                            <div className="col-sm-2">
                              <button type="submit" id="button-filter" className="btn btn-primary btn-icon">
                                <i className="icon-funnel-outline"></i> Filter
                              </button>
                            </div>
                          </div>
            
                        </div>
                      
                    </div>
            
                    <div id="main-content">
           
                      
                            <h1>Agent Locations</h1>
                            <MapContainer center={[-26.1952,28.034]} zoom={6} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[-26.1952,28.034]}>
        <Popup>
          Agent 1
        </Popup>
      </Marker>
      <Marker position={[-26.1952,28.134]}>
        <Popup>
          Agent 2
        </Popup>
      </Marker>
    </MapContainer>
                      

                      
                      

                      
                    </div>
            
            
                  </div>
            
            
                </div>
              </div>
            
            
                    </div>);
    }
}


export default Locations;