import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import {GetSimPack} from "../../services/api.simpack";
import TopHeader from '../../components/topheader';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
//import DT from 'datatables.net-bs5';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import { Card, CardBody, CardHeader, CardTitle,Dropdown } from 'react-bootstrap';
import { Navigate } from 'react-router';


const columns = [
  { data: 'id' },
  { data: 'network' },
  { data: 'prod_Code' },
  { data: 'serial_No'},
  { data: 'status' },
  { data: 'id' },

];
const columnss= [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'serial_No', headerName: 'Serial Number', width: 130 },
  { field: 'prod_Code', headerName: 'Product Code', width: 130 },
  { field: 'network', headerName: 'Network', width: 130 },
  { field: 'status', headerName: 'Status', width: 130 },
  { field: 'createDate', headerName: 'Upload Date', width: 130 },
  {
    field: '',
    headerName: 'Actions',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.id || ''} ${row.serial_No || ''}`,
  },
];


class SimPackDropDown extends React.Component{

  constructor(props){
    super(props);
  }

  render(){
    return (<Dropdown>
      <Dropdown.Toggle>
      Action
      <span className='caret'></span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
      <Dropdown.Item onClick=''>Update SimPack</Dropdown.Item>
      <Dropdown.Item onClick=''>Delete SimPack</Dropdown.Item>
      </Dropdown.Menu>
      </Dropdown>);
      }
}




class SimPack extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
        this.state={"redirect":"",data:[],"module":"simpack"}
        this.goToUploadSimPack=this.goToUploadSimPack.bind(this);
        DataTable.use(DT);
    }

    componentDidMount(){
      this.getSimPack();
    }


    goToUploadSimPack(){
      this.setState({redirect:"uploadsim"})
    }
  
  
      getSimPack(){
        GetSimPack().then(res=>{
          this.setState({"data":res})
           
        })
    }




    render(){

      if(this.state.redirect=="uploadsim"){
        return <Navigate to="add" />
      }

        return (<div>



<div id="container">

 
    
<div className="sidebar">

<div className="logo">


<div className="logo-content">
       <img src={logo}   />
        
       
    </div>

</div>


<Sidebar />

</div>
    <div className="main">

     
     <TopHeader />


      <div id="content">


        <div className="actions">

          <div className="title">
            <h4 className="text-muted my-2">
              <i className="icon-person-outline"></i>
              <span>SimPack</span>
            </h4>
          </div>

          <div className="btns">
            <button type="button" data-bs-toggle="collapse" href="#filters" aria-expanded="false" aria-controls="filters" title="Filter" className="btn btn-outline-secondary me-2">
              <i className="icon-funnel-outline"></i>&nbsp;
              <i className="icon-chevron-down-outline small"></i>
            </button>

            <a className="btn btn-primary btn-icon ms-1" onClick={this.goToUploadSimPack}>
              <i className="icon-add-circle-outline"></i>
              <span>Upload SimPack</span>
            </a>
          </div>

        </div>


        <div id="filters" className="collapse mx-3">
          

           
            <div className="card card-block p-3">

              <div className="row">
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Search</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" name="filter[search]" value="" placeholder="Serial Number" id="search" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Serial Number</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" name="serialNo" value="" placeholder="Serial Number" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Product Code</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" name="prod_code" value="" placeholder="Product code"  />
                    </div>
                  </div>
                </div>


                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="input-model">Status</label>
                    <div className="col-sm-10">
                      <select name="filter[status]" id="filter-by-status" className="form-control">
                        <option value="">- All -</option>
                        <option value="active" data-v-option>Active</option>
                        <option value="inactive" data-v-option>Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-sm-2">
                  <button type="submit" id="button-filter" className="btn btn-primary btn-icon">
                    <i className="icon-funnel-outline"></i> Filter
                  </button>
                </div>
              </div>

            </div>
          
        </div>

        <div id="main-content">

          <div className="users card-block" data-v-users>
            

              <div className="bulk-actions px-2 mb-2" style={{display:"none"}}>
                
                <button type="submit" name="action" value="delete" aria-expanded="false" title="Delete selected" className="btn btn-outline-secondary bg-body-tertiary text-danger">
                  <i className="icon-trash-outline"></i> Delete selected
                </button>
              </div>


             

        


<Card>
  <CardHeader>SimPacks</CardHeader>
  <CardBody>


 
              <DataTable data={this.state.data} columns={columns} className="display"
               slots={{
                5: (data, row) => (
                  
                    <SimPackDropDown />
                )
            }}
            options={{
                responsive: true,
                select: true,
            }}>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Network</th>
                    <th>Product code</th>
                    <th>Serial number</th>
                    <th>Status</th>
                    <th>Actions</th>
                   
                </tr>
            </thead>
        </DataTable>
        </CardBody>
        </Card>


          </div>



          

          
        </div>


      </div>


    </div>
  </div>


        </div>);

    }
}


export default SimPack;